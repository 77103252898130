import React, { useState, useEffect, useRef, useContext } from 'react';
import CustomButton from '../reusableComponents/CustomButton';
import ArrowButton from '../reusableComponents/ArrowButton.js';
import { LanguageContext } from '../../language/LanguageContext.js';
import './CV.scss'; // Import the CSS file here
import { throttle } from 'lodash';
import ScrollHelper from '../helpers/ScrollHelper';
import DeviceHelper from '../helpers/DeviceHelper';

const CV = () => {
    const { dictionary } = useContext(LanguageContext);
    const [cvPoints, setCvPoints] = useState([...dictionary.cv.educationPoints, ...dictionary.cv.workExperiencePoints].sort((a, b) => a.order - b.order));
    const [visiblePoints, setVisiblePoints] = useState(cvPoints.map(() => false));
    const [isEducationInactive, setIsEducationInactive] = useState(true);
    const [isWorkingExperienceInactive, setIsWorkingExperienceInactive] = useState(true);
    const [isAllInactive, setIsAllInactive] = useState(false);

    useEffect(() => {
        let points;
        if (!isAllInactive) {
            points = [...dictionary.cv.educationPoints, ...dictionary.cv.workExperiencePoints].sort((a, b) => a.order - b.order);
        } else if (!isEducationInactive) {
            points = dictionary.cv.educationPoints;
        } else if (!isWorkingExperienceInactive) {
            points = dictionary.cv.workExperiencePoints;
        }
        setCvPoints(points);
        handleChange();
    }, [dictionary, isAllInactive, isEducationInactive, isWorkingExperienceInactive]);

    const customButtonsRef = useRef(null);

    const scrollToCustomButtons = () => {
        if (customButtonsRef.current) {
            ScrollHelper.scrollToQuery(".cv")
            if (!isEducationInactive) {
                document.getElementById("education-button").focus({ preventScroll: true });
            } else if (!isWorkingExperienceInactive) {
                document.getElementById("working-experience-button").focus({ preventScroll: true });
            } else if (!isAllInactive) {
                document.getElementById("all-button").focus({ preventScroll: true });
            }
        }
    };

    useEffect(() => {
        const handleScroll = throttle(() => {
            const viewportHeight = window.innerHeight;

            const positions = cvPoints.map((_, index) => {
                const element = document.getElementById(`cv-point-${index}`);
                if (element) {
                    const rect = element.getBoundingClientRect();
                    let isVisible;
                    if(DeviceHelper.isMobile()){
                        isVisible = rect.top + rect.height/2 <= viewportHeight;
                    }else{
                        isVisible = rect.top + rect.height <= viewportHeight;
                    }
                    return isVisible;
                }
                return false;
            });

            let element = document.querySelector('.cv .button-wrapper');
            let rect = element.getBoundingClientRect();
            let isVisible = rect.top + rect.height <= viewportHeight && rect.top + rect.height >= 0;
            if (isVisible) {
                element.classList.add('visible');
            } else {
                element.classList.remove('visible');
            }

            if (!arraysAreIdentical(visiblePoints, positions)) {
                setVisiblePoints(positions);
            }

            //For Projects
            element = document.querySelector('.projects .projects-wrapper');
            rect = element.getBoundingClientRect();
            isVisible = rect.top <= viewportHeight;
            if (isVisible) {
                if (!element.classList.contains('visible')) {
                    element.classList.add('visible');
                }
            } else {
                if (element.classList.contains('visible')) {
                    element.classList.remove('visible');
                }
            }

             //For Contact
             element = document.querySelector('.contact form');
             rect = element.getBoundingClientRect();
             isVisible = rect.top <= viewportHeight;
             if (isVisible) {
                 if (!element.classList.contains('visible')) {
                     element.classList.add('visible');
                 }
             } else {
                 if (element.classList.contains('visible')) {
                     element.classList.remove('visible');
                 }
             }
        }, 200); // Adjust the throttle delay as needed

        const arraysAreIdentical = (arr1, arr2) => {
            if (arr1.length !== arr2.length) return false;
            return arr1.every((value, index) => value === arr2[index]);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [cvPoints, visiblePoints, setVisiblePoints]);

    const gridTemplateColumnsValue = `repeat(${cvPoints.length}, auto)`;

    const handleEducationClick = () => {
        setIsEducationInactive(false);
        setIsAllInactive(true);
        setIsWorkingExperienceInactive(true);
        setCvPoints(dictionary.cv.educationPoints);
        handleChange();
    };

    const handleWorkExperienceClick = () => {
        setIsWorkingExperienceInactive(false);
        setIsAllInactive(true);
        setIsEducationInactive(true);
        setCvPoints(dictionary.cv.workExperiencePoints);
        handleChange();
    };

    const handleAllClick = () => {
        setIsWorkingExperienceInactive(true);
        setIsEducationInactive(true);
        setIsAllInactive(false);
        setCvPoints([...dictionary.cv.educationPoints, ...dictionary.cv.workExperiencePoints].sort((a, b) => a.order - b.order));
        handleChange();
    };

    const handleChange = () => {
        setTimeout(() => {
            window.dispatchEvent(new Event('scroll'));
        }, 10); 
        document.querySelector('.lifeline-wrapper').classList.add('flash');
        setTimeout(() => {
            document.querySelector('.lifeline-wrapper').classList.remove('flash');
        }, 200);
    }

    return (
        <div className='cv' ref={customButtonsRef}>
            <div className='button-wrapper'>
                <div>
                    <CustomButton id="all-button" text={dictionary.cv.buttons.combined} onClick={handleAllClick} inactive={isAllInactive} />
                </div>
                <div>
                    <CustomButton id="education-button" text={dictionary.cv.buttons.education} onClick={handleEducationClick} inactive={isEducationInactive} />
                </div>
                <div>
                    <CustomButton id="working-experience-button" text={dictionary.cv.buttons.workingExperience} onClick={handleWorkExperienceClick} inactive={isWorkingExperienceInactive} />
                </div>
            </div>
            <div className='lifeline-wrapper' style={{ gridTemplateRows: gridTemplateColumnsValue + 1 }}>
                {cvPoints.map((cvPoint, index) => (
                    <div key={index} id={`cv-point-${index}`}
                        style={{ gridRow: index + 1 }}
                        className={`cv-point ${index % 2 === 0 ? 'slide-in-left' : 'slide-in-right'} ${visiblePoints[index] ? 'visible' : 'start'}`}>
                        <h2>{cvPoint.title}</h2>
                        <p className="cv-point-date">{cvPoint.date}</p>
                        <p>{cvPoint.text}</p>
                        {cvPoint.points && cvPoint.points.length > 0 && (
                            <ul>
                                {cvPoint.points.map((point, pointIndex) => (
                                    <li key={pointIndex}>{point}</li>
                                ))}
                            </ul>
                        )}
                        <div className="dot"></div>
                    </div>
                ))}
            </div>
            <ArrowButton onClick={scrollToCustomButtons} />
        </div>
    );
};

export default CV;