import React, { useState} from 'react';
import { dictionaryList} from '.';
import {LanguageContext} from './LanguageContext';

// it provides the language context to app
export function LanguageProvider({ children }) {
  const [userLanguage, setUserLanguage] = useState('en');
  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    userLanguageChange: selected => {
      console.log(selected);
      setUserLanguage(selected);
      window.localStorage.setItem('rcml-lang', userLanguage);
    }
  };
  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
};