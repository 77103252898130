import React, {useContext} from 'react';
import { LanguageContext } from '../../language/LanguageContext.js';
import ArrowButton from '../reusableComponents/ArrowButton.js';
import './Header.scss';
import ScrollHelper from '../helpers/ScrollHelper';

const Header = () => {
    const { dictionary } = useContext(LanguageContext);

    return (
        <div className="header">
            <div className="animation-wrapper">
                <div className='info-wrapper'>
                    <h1>Tobias Henning</h1>
                    <p>{dictionary.header.title}</p>
                </div>
            </div>
            <ArrowButton direction='bottom' onClick={() => ScrollHelper.scrollToQuery(".cv")} />
        </div>
    );
};

export default Header;