import React,{useContext, useEffect, useState} from 'react';
import './Footer.scss';
import { LanguageContext } from '../../language/LanguageContext';
import githubLogoBlack from '../../assets/img/home/github-mark.png';
import githubLogoWhite from '../../assets/img/home/github-mark-white.png';


const Footer = () => {
    const { dictionary } = useContext(LanguageContext);
    const currentYear = new Date().getFullYear();

    const [githubLogo, setGithubLogo] = useState(githubLogoBlack);

    useEffect(() => {
        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const updateLogo = (e) => {
            setGithubLogo(e.matches ? githubLogoWhite : githubLogoBlack);
        };

        // Initial check
        updateLogo(darkModeMediaQuery);

        // Listen for changes
        darkModeMediaQuery.addEventListener('change', updateLogo);

        // Cleanup listener on component unmount
        return () => {
            darkModeMediaQuery.removeEventListener('change', updateLogo);
        };
    }, []);

    return (
        <>
            <footer className='about-me'>
                <div className='github'>
                    <a href="https://github.com/Duocervisia" target="_blank" rel="noopener noreferrer">
                        <img src={githubLogo} alt="GitHub Logo" />
                    </a>
                </div>
                <div className='contact-info'>
                    <p>{dictionary.navigation.contact}: <a className='fancy-link' href="mailto:me@tobiashenning.dev">me@tobiashenning.dev</a></p>
                </div>
                <div className='copyright'>
                    <p>© {currentYear} {dictionary.footer.text}</p>
                </div>
            </footer>
        </>
    );
};

export default Footer;