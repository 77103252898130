import React, { useContext } from 'react';
import './Projects.scss';
import { LanguageContext } from '../../language/LanguageContext';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Projects = () => {
    const { dictionary } = useContext(LanguageContext);
    const navigate = useNavigate();
    const shownProjects = [1, 2, 3, 5];

    const handleClick = (id) => {
        navigate('/project/' + id);
    };

    return (
        <>
            <div className='projects'>
                <h2>{dictionary.navigation.projects}</h2>
                <div className='projects-wrapper visible'>
                    {dictionary.projects.elements
                        .filter(project => shownProjects.includes(project.id))
                        .map((project, index) => (
                            <div key={index} className="card" onClick={() => handleClick(project.id)}>
                                <img src={require(`../../assets/img/${project.cardImage}`)} alt="" />
                                <div className="card-background"></div>
                                <div className="card-content">
                                    <h2>{project.title}</h2>
                                    <p>{project.short}</p>
                                    <Link className="fancy-link" to={`/project/${project.id}`}>{dictionary.projects.projectLinkText} &rarr;</Link>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </>
    );
};

export default Projects;