import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './CarouselComponent.scss'; // Import the CSS file
import Loader from './Loader';

const CarouselComponent = ({ mediaArray, loading }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        function checkCarouselHeight() {
            const carouselRoot = document.querySelector('.carousel-root');
            const carouselWrapper = document.querySelector('.carousel-wrapper');

            // console.log(carouselRoot && carouselWrapper); // Debugging line
            carouselWrapper.classList.remove('no-flex');

            if (carouselRoot && carouselWrapper) {
                if (carouselWrapper.clientHeight < carouselRoot.clientHeight) {
                    carouselWrapper.classList.add('no-flex');
                } else {
                    carouselWrapper.classList.remove('no-flex');
                }
            }
        }

        // Initial check
        checkCarouselHeight();

        // Add event listener for window resize
        window.addEventListener('resize', checkCarouselHeight);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', checkCarouselHeight);
        };
    }, []);

    useEffect(() => {
        if (!loading) {
            const checkMediaElements = () => {
                const mediaElements = document.querySelectorAll('.carousel-media');

                if (mediaElements.length > 0) {
                    // setIsLoaded(true);

                    let loadedCount = 0;

                    mediaElements.forEach((element) => {
                        if (element.tagName === 'IMG') {
                            element.onload = () => {
                                loadedCount++;
                                if (loadedCount === mediaElements.length) {
                                    setIsLoaded(true);
                                }
                            };
    
                            // Check if the image is already loaded
                            if (element.complete) {
                                loadedCount++;
                                if (loadedCount === mediaElements.length) {
                                    setIsLoaded(true);
                                }
                            }
                        } else if (element.tagName === 'VIDEO') {
                            element.onloadeddata = () => {
                                loadedCount++;
                                if (loadedCount === mediaElements.length) {
                                    setIsLoaded(true);
                                }
                            };
    
                            // Check if the video is already loaded
                            if (element.readyState >= 3) {
                                loadedCount++;
                                if (loadedCount === mediaElements.length) {
                                    setIsLoaded(true);
                                }
                            }
                        }
                    });
                } else {
                    // Retry after a short delay if media elements are not found
                    setTimeout(checkMediaElements, 100);
                }
            };

            checkMediaElements();
        }
    }, [loading]);

    return (
        <div className='carousel-wrapper'>
            {(loading || !isLoaded) ? (
                <div className='carousel-loader'>
                    <Loader />
                </div> 
            ): <></>}
            <Carousel autoPlay infiniteLoop interval="10000" showThumbs={false} showStatus={false} className={loading || !isLoaded ? '' : 'show'}>
                {mediaArray.map((media, index) => {
                    const mediaType = media.endsWith('.webm') || media.endsWith('.mp4') ? 'video' : 'image';
                    return (
                        <div key={index} className='carousel-item'>
                            {mediaType === 'image' && (
                                <img src={require(`../../assets/img/${media}`)} alt={`Media ${index}`} className='carousel-media' />
                            )}
                            {mediaType === 'video' && (
                                <video playsInline autoPlay muted loop className='carousel-media'>
                                    <source src={require(`../../assets/video/${media}`)} type="video/webm" />
                                </video>
                            )}
                        </div>
                    );
                })}
            </Carousel>
            
        </div>
    );
};

export default CarouselComponent;