import React from 'react';
import './CustomButton.scss';
import Loader from './Loader';

const CustomButton = ({ id, text, onClick, inactive, type = 'button', disabled, loading = false }) => {
    const handleClick = () => {
        // Perform any desired actions here
        // Call the onClick if it is passed as a prop
        if (onClick) {
            onClick();
        }
    };

    return (
        <button 
            type={type} 
            onClick={handleClick} 
            id={id}
            className={`custom-button ${inactive ? 'inactive' : ''}`}
            disabled={disabled || loading} // Disable button when loading
        >
            {loading ? <><Loader />{' '}</> : text}        
        </button>
    );
};

export default CustomButton;
