import React, { useContext } from 'react';
import { languageOptions } from '../../language/index';
import { LanguageContext } from '../../language/LanguageContext';
import './LanguageSelector.scss';

export default function LanguageSelector() {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  // // set selected language by calling context method
  const handleLanguageChange = selectedLanguage => () => {
    userLanguageChange(selectedLanguage);
  }

  const entries = Object.entries(languageOptions);
  return (
    <div className="language-selector">
      {entries.map(([id, name], index) => (
        <React.Fragment key={id}>
          <button className={`language-button fancy-link ${userLanguage === id ? "active": ""}`} onClick={handleLanguageChange(id)} value={id}>{id}</button>
          {index !== entries.length - 1 && <span>|</span>}
        </React.Fragment>
      ))}
    </div>
  );
};