import React, { useEffect, useLayoutEffect, useRef } from 'react';
import './Fonts.css';
import './App.css';
import Home from './components/pages/Home';
import Project from './components/pages/Project';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'; // Import BrowserRouter
import { LanguageProvider } from './language/LanguageProvider';
import { MediaProvider, useMedia } from './components/context/MediaContext'; // Correct import
import Loader from './components/reusableComponents/Loader'; // Import the Loader component
import { motion, AnimatePresence } from 'framer-motion';

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
};

function App() {
  return (
    <MediaProvider>
      <LanguageProvider>
        <BrowserRouter>
          <ScrollRestoration />
          <AppContent />
        </BrowserRouter>
      </LanguageProvider>
    </MediaProvider>
  );
}

function ScrollRestoration() {
  const { pathname } = useLocation();
  const scrollPositions = useRef({});
  const previousPathname = useRef();

  useLayoutEffect(() => {
    // Save the scroll position of the previous path
    if (previousPathname.current) {
      scrollPositions.current[previousPathname.current] = window.scrollY;
    }
    // Update the previous pathname
    previousPathname.current = pathname;
  }, [pathname]);
  
  useLayoutEffect(() => {
    // If the scroll position was saved, restore it, and then delete it
    if (scrollPositions.current[pathname]) {
      let top = scrollPositions.current[pathname];
      const observer = new MutationObserver(() => {
        window.scrollTo({
          top: top
        });
        observer.disconnect();
      });
  
      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
  
      delete scrollPositions.current[pathname];
    }
  }, [pathname]);

  return null;
}

function AppContent() {
  const { homeMediaLoaded, setHomeMediaLoaded, setProjectMediaLoaded } = useMedia();
  const location = useLocation();

  useEffect(() => {
    const fetchHomeData = async () => {
      try {
        const globalImages = importAll(require.context('./assets/img/home', false, /\.(png|PNG|jpe?g|JPG|svg)$/));
        // const globalVideos = importAll(require.context('./assets/video/home', false, /\.(mp4|webm|ogg)$/));
        // console.log('Imported home images:', globalImages); // Debugging line
        // console.log('Imported home videos:', globalVideos); // Debugging line
        await cacheMedia(globalImages, 'image');
        // await cacheMedia(globalVideos, 'video');
        setHomeMediaLoaded(true);
      } catch (error) {
        console.error('Error loading home media:', error);
      }
    };

    const fetchProjectData = async () => {
      try {
        const globalImages = importAll(require.context('./assets/img/projects', false, /\.(png|PNG|jpe?g|JPG|svg)$/));
        const globalVideos = importAll(require.context('./assets/video/projects', false, /\.(mp4|webm|ogg)$/));
        // console.log('Imported project images:', globalImages); // Debugging line
        // console.log('Imported project videos:', globalVideos); // Debugging line
        await cacheMedia(globalImages, 'image');
        await cacheMedia(globalVideos, 'video');
        setProjectMediaLoaded(true);
      } catch (error) {
        console.error('Error loading project media:', error);
      }
    };

    fetchHomeData();
    fetchProjectData();
  }, [setHomeMediaLoaded, setProjectMediaLoaded]);

  function importAll(r) {
    return r.keys().map(r);
  }

  const cacheMedia = async (srcArray, type) => {
    const promises = srcArray.map((src) => {
      return new Promise((resolve, reject) => {
        let media;
        if (type === 'image') {
          media = new Image();
          media.src = src.default || src; // Ensure src is correctly set
          media.onload = () => resolve(true);
          media.onerror = () => reject(new Error(`Failed to load ${type}: ${media.src}`));
        } else if (type === 'video') {
          media = document.createElement('video');
          media.src = src.default || src; // Ensure src is correctly set
          media.onloadeddata = () => resolve(true);
          media.onerror = () => reject(new Error(`Failed to load ${type}: ${media.src}`));
        }
      });
    });

    await Promise.all(promises);
  };

  return (
      <AnimatePresence mode='wait'>
        {homeMediaLoaded ? (
          <motion.div
            key={location.pathname}
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={variants}
            transition={{ duration: 0.2 }}
          >
            <Routes location={location} key={location.pathname}>
              <Route path="/" element={<Home />} />
              <Route path="/project/:id" element={<Project />} />
            </Routes>
          </motion.div>
        ) : (
          <motion.div
            key="loader"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={variants}
            transition={{ duration: .5 }}
          >
            <Loader />
          </motion.div>
        )}
      </AnimatePresence>
  );
}


export default App;