import React, { useState } from 'react';
import './ArrowButton.scss';

const ArrowButton = ({ direction="", onClick }) => {
    const [hasClicked, setHasClicked] = useState(false);

    const handleClick = () => {
        setHasClicked(true);
        onClick();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleClick();
        }
    };

    return (
        <div className='arrow-wrapper'>
            <div className='wiggle-wrapper'>
                <div
                    role='button'
                    tabIndex={0}
                    className={`arrow ${direction} ${!hasClicked ? 'wiggle' : ''}`}
                    onClick={handleClick}
                    onKeyDown={handleKeyDown}
                ></div>
            </div>
        </div>
    );
};

export default ArrowButton;