class ScrollHelper {
    static scrollToElement(element){
        const targetPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const startPosition = window.pageYOffset;
        const distance = targetPosition - startPosition;
        const duration = 1000; // Duration in milliseconds
        let start = null;
    
        const easeInOutQuad = (t) => {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        };
    
        const step = (timestamp) => {
            if (!start) start = timestamp;
            const progress = timestamp - start;
            const progressPercentage = Math.min(progress / duration, 1);
            const ease = easeInOutQuad(progressPercentage);
            window.scrollTo(0, startPosition + distance * ease);
            if (progress < duration) {
                window.requestAnimationFrame(step);
            }
        };
    
        window.requestAnimationFrame(step);
    }

    static scrollToQuery(query){
        let element =  document.querySelector(query);
        this.scrollToElement(element);
    }
}
export default ScrollHelper;