class ColorHelper {

    static hexToRgb = (hex) => {
        // Remove the hash at the start if it's there
        hex = hex.replace(/^#/, '');
    
        // If the hex value is 3 digits, convert it to 6 digits
        if (hex.length === 3) {
            hex = hex.split('').map(char => char + char).join('');
        }
    
        // Parse r, g, b values
        let bigint = parseInt(hex, 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;
    
        return [r, g, b];
    };

     // Helper function to convert RGB to hex
     static rgbToHex = (r, g, b) => {
        return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
    };

    // Function to interpolate between two hex colors
    static interpolateColor = (color1, color2, factor) => {
        const color1RGB = ColorHelper.hexToRgb(color1);
        const color2RGB = ColorHelper.hexToRgb(color2);
    
        const result = color1RGB.map((val, index) => {
            return Math.round(val + factor * (color2RGB[index] - val));
        });
    
        return ColorHelper.rgbToHex(result[0], result[1], result[2]);
    };

     static opacityColor = (color1, opacity) => {
        const color1RGB = ColorHelper.hexToRgb(color1);
    
        return `rgba(${color1RGB[0]}, ${color1RGB[1]}, ${color1RGB[2]}, ${opacity})`;
    }
}
export default ColorHelper;