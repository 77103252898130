import React, { createContext, useContext, useState } from 'react';

const MediaContext = createContext();

export const MediaProvider = ({ children }) => {
    const [homeMediaLoaded, setHomeMediaLoaded] = useState(false);
    const [projectMediaLoaded, setProjectMediaLoaded] = useState(false);

    return (
        <MediaContext.Provider value={{ homeMediaLoaded, setHomeMediaLoaded, projectMediaLoaded, setProjectMediaLoaded }}>
            {children}
        </MediaContext.Provider>
    );
};

export const useMedia = () => useContext(MediaContext);