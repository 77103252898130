import React, { useState, useContext, useEffect } from 'react';
import './ContactForm.scss';
import emailjs from '@emailjs/browser';
import CustomButton from '../reusableComponents/CustomButton';
import { LanguageContext } from '../../language/LanguageContext.js';


const ContactForm = () => {
    const { dictionary } = useContext(LanguageContext);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [isSuccess, setIsSuccess] = useState(null);
    const [isFormButtonLoading, setIsFormButtonLoading] = useState(false);

    emailjs.init("sX6rYBPFyFdFKzABB");

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsFormButtonLoading(true); // Set loading status to true
        
        emailjs.send("service_harxwcc", "template_ifmm0rh", {
            to_name: "Tobias Henning",
            from_name: name,
            message: message,
            reply_to: email,
        }).then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            setIsFormButtonLoading(false); // Set loading status to false
            setIsSuccess(true); // Set success status to true
            setShowDialog(true); // Show dialog on success
        }).catch((err) => {
            console.error('FAILED...', err);
            setIsFormButtonLoading(false); // Set loading status to false
            setIsSuccess(false); // Set success status to false
            setShowDialog(true); // Show dialog on failure
        });
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const isFormValid = () => {
        return name.trim() !== '' && email.trim() !== '' && message.trim() !== '';
    };

    const disableTabIndex = () => {
        const formElements = document.querySelectorAll('form input, form textarea, form button');
        formElements.forEach(element => {
            element.setAttribute('tabindex', '-1');
        });
    };

    const restoreTabIndex = () => {
        const formElements = document.querySelectorAll('form input, form textarea, form button');
        formElements.forEach(element => {
            element.removeAttribute('tabindex');
        });
    };

    useEffect(() => {
        if (showDialog) {
            disableTabIndex();
        } else {
            restoreTabIndex();
        }
    }, [showDialog]);

    return (
        <div className='contact'>
            <h2>{dictionary.contact.title}</h2>
            <form onSubmit={handleSubmit}>
                <label htmlFor="name">{dictionary.contact.form.name}:</label>
                <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <label htmlFor="email">{dictionary.contact.form.email}:</label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <label htmlFor="message">{dictionary.contact.form.message}:</label>
                <textarea
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                ></textarea>

                <CustomButton loading={isFormButtonLoading} type="submit" text={dictionary.contact.form.send} disabled={!isFormValid()} />
            </form>

            {showDialog && (
                <>
                    <div className="dialog-background"></div>
                    <div className="dialog">
                        <p>
                          {isSuccess ? (
                            dictionary.contact.sendMail.success
                          ) : (
                            <>
                              {dictionary.contact.sendMail.error} <a className='fancy-link' href="mailto:me@tobiashenning.dev">me@tobiashenning.dev</a>
                            </>
                          )}
                        </p>
                        <CustomButton onClick={handleCloseDialog} text={dictionary.contact.sendMail.close}/>
                    </div>
                </>
            )}
        </div>
    );
};

export default ContactForm;