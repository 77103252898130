import React, { useContext, useEffect } from 'react';
import './Navigation.scss';
import { LanguageContext } from '../../language/LanguageContext';
import { throttle } from 'lodash';
import { useNavigate } from 'react-router-dom';
import ColorHelper from '../helpers/ColorHelper';
import ScrollHelper from '../helpers/ScrollHelper';

const Navigation = ({type="home"}) => {
    const { dictionary } = useContext(LanguageContext);
    const entries = dictionary.navigation;
    const navigate = useNavigate();

    const scrollToElement = (id) => {
        let query;

        switch (id) {
            case 'home':
                query = '.parallax-container';
                break;
            case 'cv':
                query = '.cv';
                break;
            case 'projects':
                query = '.projects';
                break;
            case 'contact':
                query = '.contact';
                break;
            default:
                break;
        }
    
        ScrollHelper.scrollToQuery(query);
    };

    useEffect(() => {
        if(type !== "home")
            return;

        let lastRun = false;
        const handleScroll = throttle(() => {
            const cvElement = document.querySelector('.cv');
            if (cvElement) {
                const rect = cvElement.getBoundingClientRect();
                let height = window.scrollY + rect.top;
                let opacity = window.scrollY / height;
                if (opacity > 1) {
                    opacity = 1;
                    if(lastRun){
                        return;
                    }
                    lastRun = true;
                }else{
                    lastRun = false;
                }
                document.querySelector('.navigation .background').style.opacity = opacity;
                document.querySelector('.header .info-wrapper').style.setProperty('opacity', 1 - opacity * 2, 'important');
                
                const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
                if (!darkModeMediaQuery.matches) {
                    //remove color style
                    document.querySelector('.navigation').style.color = '';
                    document.querySelectorAll('.navigation button').forEach(button => {
                        button.style.color = '';
                    });
                    return;
                }
                const rootStyles = getComputedStyle(document.documentElement);
                const textColor = rootStyles.getPropertyValue('--primary-text-color').trim();
                const invertedTextColor = rootStyles.getPropertyValue('--inverted-primary-text-color').trim();

                const specialColor = rootStyles.getPropertyValue('--special-color').trim();

                

                const interpolatedColor = ColorHelper.interpolateColor(invertedTextColor,textColor, opacity);
                const interpolatedBorderColor = ColorHelper.opacityColor(specialColor, opacity);

                document.querySelector('.navigation').style.color = interpolatedColor;
                document.querySelector('.navigation').style.borderColor = interpolatedBorderColor;

                document.querySelectorAll('.navigation button').forEach(button => {
                    button.style.color = interpolatedColor;
                });
                document.querySelector('.language-selector').style.color = interpolatedColor;
                document.querySelectorAll('.language-selector button').forEach(button => {
                    button.style.color = interpolatedColor;
                });

            }
        }, 10); // Adjust the throttle delay as needed

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        
    }, [type]);
    console.log(type, type === "project");
    
    const handleHomeClick = () => {
        navigate('/');
    };

        

    return (
        <div className={`navigation ${type}-type`}>
            <div className={'background'}></div>
            {type === 'home' ? (
                Object.keys(entries).map((key, index) => (
                    <React.Fragment key={key}>
                        <button className={'navigation-button fancy-link'} onClick={() => scrollToElement(key)}>
                            {entries[key]}
                        </button>
                        {index !== Object.keys(entries).length - 1 && <span>|</span>}
                    </React.Fragment>
                ))
            ) : type === 'project' ? (
                <button className={'navigation-button fancy-link'} onClick={handleHomeClick}>
                    {entries.home}
                </button>
            ) : (
                <></>
            )}
        </div>
    );
};

export default Navigation;