import React from 'react';
import homeImage from '../../assets/img/home/top-background.jpg';
import projectImage from '../../assets/img/home/project-background.jpg';
import './Home.scss'; // Import the CSS file here
// import { LanguageContext } from '../../language/LanguageContext';
import LanguageSelector from '../pageComponents/LanguageSelector';
import CV from '../pageComponents/CV'; // Import the new CV component
import Projects from '../pageComponents/Projects';
import Header from '../pageComponents/Header';
import Navigation from '../pageComponents/Navigation';
import Footer from '../pageComponents/Footer';
import ContactForm from '../pageComponents/ContactForm';


const Home = () => {
    // const { dictionary } = useContext(LanguageContext);

    return (
        <div>
            <Navigation />
            <LanguageSelector />
            <section className="parallax-container">
                <div className='parallax-image-wrapper'>
                    <img src={homeImage} alt="Home" className="parallax-image" />
                </div>
                <div className='parallax-content'>
                    <Header />
                    <div className='buffer shadow'></div>
                    <CV />
                </div>
            </section>
            <div className='buffer'></div>
            <section style={{ backgroundImage: `url(${projectImage})` }}>
                <Projects />
            </section>
            <div className='buffer'></div>
            <ContactForm />
            <div className='buffer'></div>
            <Footer />
        </div>
    );
};

export default Home;